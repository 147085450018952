/* Reset some default styles */
body, ul {
    margin: 0;
    padding: 0;
}

/* Style the header/navigation bar */
header {
    background-color: #a4384a;
    padding: 10px 0;
    text-align: center;
    top: 0%;
}
.welcome-text {
    text-align: center;
}

.welcome-text h2 {
    font-size: large;
    margin: 0;
}
.logo {
    text-align: center;
    font-size: 20px;
    color: #f1ebeb;
    font-weight: bold;
    margin: 0;
}
nav ul {
    list-style: none;
    text-align: right;
}
.dashboard-title {
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0;
}
nav li {
    display: inline;
    margin-right: 20px;
}

nav a {
    text-decoration: none;
    color: #fff;
}

/* Style the icons in the navigation bar */
nav img {
    vertical-align: middle;
    margin-right: 5px;
}

/* Style circular containers */
.circle-container {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 600px;
    margin: auto;
}
.circle {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: #7d233c;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin: 10px;
    top: 50%;
    display: inline-block;
    transition: background-color 0.3s;
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    transition: background-color 0.3s;
    flex: 0 0 calc(50% - 40px); 
}

.circle a {
    text-decoration: none;
    color: #fff;
}

.circle:hover {
    background-color: #e65585;
}


.dashboard-links ul {
    list-style: none;
    padding: 0;
}

.dashboard-links li {
    margin: 10px 0;
}

.dashboard-links a {
    text-decoration: none;
    color: #f9f6f6;
    font-weight: bold;
}

/* Style the footer */
footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links li {
    display: inline;
    margin: 0 10px;
}

.footer-links a {
    text-decoration: none;
    color: #fff;
}

.chat-button {
    position: fixed;
    bottom: 40px;
    right: 20px;
    z-index: 1;
    background: #db3450;
    color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 10px;
    font-size: 10px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.chat-button:hover {
    background: rgb(124, 40, 55);
}
