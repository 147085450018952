/* Styling for the chat container */
.chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #791c1c;
}

/* Styling for the chat messages box */
.chat-box {
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 300px;
}

/* Styling for individual chat messages */
.message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    display: inline-block;
    max-width: 80%;
}

/* Styling for user messages */
.user-message {
    background-color: #db3455;
    color: #fff;
    float: right;
}

/* Styling for other users' messages */
.other-message {
    background-color: #ccc;
    float: left;
}

/* Styling for the chat input area */
.chat-input {
    margin-top: 15px;
}

/* Styling for the message input field */
#message-input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

/* Styling for the send button */
#send-button {
    width: 18%;
    padding: 10px;
    background-color: #db346c;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Hover effect for the send button */
#send-button:hover {
    background-color: #a52950;
}
