/* Pawar_Anushka_1002071263, Pulipati_Dikshitha_1002033308 */
/* Sai Abhijith Reddy_Nimmanapalli_1002074468 */
/* Khan_Tanya_1001670905, Manavalan_Jeeva_1002067528 */
*{
    margin: 0;
    padding: 0;

}
.header{
    min-height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)),url(/public/Images/banner.jpeg);                 
    background-position: center;
    background-size: cover;
    position: relative;
}
.nav-links ul{
    display: flex;
    list-style:square;
    

}

.nav-links li {
    
    margin-left: auto;
}

.nav-links li a {
    text-decoration: none;
    color: coral;
    font-weight: bold;
    font-size: 14px;
    
}
.logo-link {
    position: absolute;
    top: 0;
    left: 0;
    
}

.logo{
    width: 100px;
    height: auto; 
    
}

.text-box{
    width: 90%;
    color:#ff7f00;
    position:absolute;
    top:45%;
    left:50%;
    transform: translate(-50%,-50%);
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    
}

.text-box h1{
    font-size: 62px;
}

.text-box p{
    font-size: 20px;
    margin: 10px 0 40px;
    color:skyblue;
    font-weight: bold;
    
}


/* <*-----AboutUs------> */


.AboutUs{
    width: 90%;
    color:coral;
    position:absolute;
    top:45%;
    left:50%;
    font-size: 20px;
    font-weight: bold;
    text-align:center;
    
}

h1{
font-size: 26px;
font-weight: bold; 
text-align:center;
color:coral;
}

p{
  color:skyblue;
  font-weight: 300;
  font-size: 30px;
  line-height: 26px;
  padding:10px;
  text-align:center;
   
    
}

h3{
font-size: 26px;
font-weight: bold; 
text-align:center;
color:coral;
}

.AboutUs-col img{
    width:100%;
    
}

html{
    
    scroll-behavior: smooth;
}